.body {
  font-family: "Montserrat", sans-serif;
}

.intro-section {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 60px);
  width: 100%;
}

.intro-text {
  width: 60%;
  text-align: center;
  margin-top: 20%;
}

.name-img {
  height: 45px;
  padding-left: 2px;
}

.job-title {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 1.3em;
}

.job-subtitle {
  width: 60%;
  margin: auto;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  text-align: justify;
  font-size: 0.9em;
  margin-bottom: 20px;
}

a {
  color: #000;
}

.profile-pic {
  height: 90%;
  justify-content: flex-end;
  border-radius: 500px 500px 0 0;
  margin: 10px 40px;
}

.header-img {
  margin: 10px;
  height: 50px;
}

.hamburger {
  display: none;
  margin-top: 10px;
  margin-right: 10px;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* background-color: #101010; */
  background-color: #000;
}

.nav {
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  justify-content: space-between;
  display: flex;
}

.nav>.nav-header {
  display: inline;
}

.nav>.nav-header>.nav-title {
  display: inline-block;
  font-size: 22px;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.nav>.nav-links {
  display: inline;
  float: right;
  font-size: 18px;
  margin-right: 40px;
}

.nav>.nav-links>a {
  display: inline-block;
  padding: 16px 30px 16px 30px;
  text-decoration: none;
  color: #000;
}

.nav>.nav-links>a:hover {
  text-decoration: underline;
}


.skills-section {
  height: max-content;
  padding-bottom: 100px;
  width: 100%;
  background-color: #eee0d4;
}

.skills-row {
  width: 80%;
  margin: auto;
  display: flex;
  margin-bottom: 25px;
}

.skill-type {
  width: 50%;
  text-align: right;
  font-weight: 500;
}

.section-header {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  font-weight: 300;
  font-size: xx-large;
  text-align: center;
}

.about-me {
  width: 60%;
  margin: auto;
  text-align: justify;
  font-size: medium;
}

.resume-section {
  width: 100%;
  padding-bottom: 30px;
}

.resume-card {
  width: 60%;
  margin: auto;
  display: flex;
  margin-bottom: 20px;
}

.card-details {
  margin-left: 30px
}

.resume-job-title {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 1.3em;
  letter-spacing: 0.1em;
}

.card-date {
  font-size: smaller;
  letter-spacing: 1.2px;
}

.card-header {
  margin-top: 20px;
  font-weight: 600;
  letter-spacing: 0.2em;
}

.chronology {
  text-align: right;
  width: 45%;
  min-width: 140px;
  border-right: 1px solid #eee;
  padding-right: 30px;
}

.sub-header {
  margin-left: 35%;
  margin-bottom: 20px;
  font-size: large;
  font-weight: 600;
}

.edu-degree {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 1.3em;
  letter-spacing: 0.1em;
}

.edu-major {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 1em;
  letter-spacing: 0.1em;
}

.contact-section {
  width: 100%;
  background-color: #eee0d4;
  padding-bottom: 80px;
}

.contact-details {
  width: 60%;
  margin: auto;
  text-align: center;
}

.contact-icons {
  justify-content: space-around;
  font-size: 30px;
}

.contact-icons>a {
  margin: 0 10px;
  opacity: 0.8;
}

footer {
  width: 100%;
  background-color: #eee0d4;
  letter-spacing: 0.15em;
}

@media (max-width: 600px) {
  .name-img {
    height: 38px;
  }

  .intro-section {
    /* margin-top: 50%; */
    flex-direction: column-reverse;
    height: max-content;
  }

  .intro-text {
    margin-top: 70px;
    width: 100%;
  }

  .profile-pic {
    object-fit: cover;
    border-radius: 200/30;
    width: auto;
  }

  .header-img {
    height: 40px;
  }

  .about-section {
    height: max-content !important;
    padding-bottom: 20px;
  }

  .nav>.nav-links {
    position: fixed;
    left: -100%;
    top: 50px;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
  }

  .nav>.nav-links>a {
    display: block;
    width: 100%;
  }

  .resume-card {
    width: 90%;
  }

  .card-details {
    margin-left: 20px;
  }

  .resume-card {
    flex-direction: column;
  }

  .chronology {
    width: 100%;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 0px;
  }

  .resume-job-title {
    margin-top: 3px;
    font-size: medium;
  }

  .edu-degree {
    margin-top: 3px;
    font-size: medium;
  }

  .nav-links.active {
    left: 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

}

@media (max-width: 700px) {
  .skills-row {
    flex-direction: column;
  }

  .skill-type {
    text-align: left;
    margin-bottom: 2px;
    width: 90%;
  }
}